* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
}

.main-container {
  width: auto;
  height: auto;
  background-color: white;
  grid-template-rows: 100px, 600px;
  grid-template-columns: 1;
  display: grid;
  /* align-items: center;*/
  margin: 1px;
  padding: 1px;
}

.nav-bar {
  width: auto;
  height: 100px;
  background-color: azure;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  z-index: 5;
  padding-right: 50px;
  align-items: center;
  text-align: center;
}

.logo {
  width: 100px;
  height: 100px;
  grid-column: 1/2;
  margin: auto;
  padding: auto;
  display: block;
}


.name {
  width: 200px;
  height: 200px;
  grid-column: 2/3;
  z-index: 1;
  align-content: center;
  background-color: black;
}

.top-links a {
  font-size: 20px;
  text-align: center;
  z-index: 6;
  text-decoration: none;
  color: black;
  align-content: center;
}

.intro {
  grid-row: 1/2;
  color: black;
  text-align: center;
  padding-top: 40px;
  font-size: 60px;
  grid-column: 1/2;
}

.intro p {
  font-size: 25px;
  text-align: left;
  padding-left: 30px;
  width: auto;
  font-weight: 200px;
  color: black;
  margin-right: 50px
}

.mayn {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin-left: 60px;
}

.topic {
  font-size: 40px;
  text-align: center;
  padding-top: 80px;
  color: black;
  margin-left: 40px;
}

.second-container {
  width: auto;
  height: 800px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  background-color: white;
}

.third-container {
  width: auto;
  height: 800px;
  grid-column: -2/-1;
  color: white;
  text-align: center;
  background-color: black;
}

#wow {
  margin-top: 20px;
  text-align: center;
}

.nimage {
  height: 100px;
}

@media screen and (max-width: 600px) {
  .second-container {
    width: auto;
    height: 800px;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    display: block;
  }

  #content-2 {
    padding-top: 100px;
  }
}

html {
  scroll-behavior: smooth;
}

@media screen and (min-width: 280px) and (max-width: 822px) {
  #menu{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .top-links {
    margin-left: 20px;
  }

  .top-links a{
    font-size: 15px;
  }
}