* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
}

.main-container {
  width: auto;
  height: auto;
  grid-template-rows: 100px, 600px;
  grid-template-columns: 1;
  display: grid;
  border: none;
  /* align-items: center;*/
}

.nav-bar {
  width: 100%;
  height: 100px;
  background-color: azure;
  display: flex;
  grid-template-columns: repeat(5, 1fr);
  padding-right: 0%;
  position: fixed;
  z-index: 10;
}

.logo {
  width: 100px;
  height: 100px;
  grid-column: 1/2;
  margin: auto;
  padding: auto;
  display: block;
}

.name {
  width: 300px;
  height: 300px;
  grid-column: 2/3;
  z-index: 1;
  align-content: center;
  margin-left: 18px;
  background-color: white;
}

.top-links a {
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  color: black;
  align-content: center;
  background-color: transparent;
}

.top-links {
  margin-left: 120px;
}

.top-links a:hover {
  text-decoration: underline;
  text-decoration-color: rgb(207, 20, 160);
  text-decoration-thickness: 5px;
  transition: .5s;
  transition-delay: .1s;
  offset: 0.4em;
  
}

.intro {
  grid-row: 1/2;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 60px;
  grid-column: 1/2;
  display: flex;
  flex-wrap: wrap;
  z-index: 6;
  height: 800px;
  overflow: auto;
}

.intro p {
  font-size: 25px;
  text-align: left;
  padding-left: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 30px;
  align-content: flex-end;
}

.intro img {
  text-align: left;
  border: white;
  border-radius: 20px;
  margin-top: 20px;
}

#s-para {
  margin-left: 30px;
}

#t-para {
  margin-top: 30px;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.intro ul {
  margin-left: 40px;
}

.intro li {
  padding: 10px;
}

.second-container {
  width: auto;
  height: 800px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  overflow: auto;
}

@media screen and (max-width: 600px) {
  .second-container {
    width: auto;
    height: 800px;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    display: block;
  }
}

.third-container {
  width: auto;
  height: 820px;
  grid-column: -2/-1;
  color: white;
  text-align: center;
  background-color: black;
  padding-top: 70px;
}

#topic {
  font-size: 40px;
  display: block;
  margin-top: 60px;
  margin-bottom: 20px;
  width: auto;
  height: 40px;
}

#topic-u {
  font-size: 40px;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  width: auto;
  height: 40px;
}

#content-1 {
  margin: 0px;
  padding: 0px;
}

.third-container li {
  padding: 10px;
  overflow: auto;
}

.footer {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: azure;
  display: block;
  
  width: 100%;
  height: auto;
  bottom: 0;
}

.form {
  background-color: black;
}

.contact {
  text-align: center;
  grid-column: 1/2;
}

.message {
  grid-column: 2/3;
}

.sub-btn {
  width: 120px;
  height: 30px;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border-color: transparent;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 15px;
}

.sub-input-1 {
  border-radius: 5px;
  box-shadow: transparent;
  border-color: transparent;
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: rgb(255, 255, 255);
  outline: transparent;
  width: 290px;
  font-size: 15px;
  padding-left: 10px;
}

.sub-input-2 {
  border-radius: 5px;
  width: 290px;
  height: 200px;
  margin-bottom: 20px;
  box-shadow: transparent;
  border-color: transparent;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  outline: transparent;
  text-align: start;
  line-height: 20px;
  font-size: 15px;
  padding-left: 10px;
}

.sub-btn:hover {
  background-color: azure;
  transition: .3s;
  width: 125px;
  height: 35px;
}

.nimage {
  height: 100px;
  margin-top: 20px;
}

.icon-ii {
  margin-left: 10px;
  margin-right: 10px;
  color: rgb(194, 11, 179);
  width: 20px;
  height: 20px;
}

.icon-iit {
  margin-left: 10px;
  margin-right: 10px;
  color: rgb(51, 139, 255);
  width: 20px;
  height: 20px;
}

.top-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}



.second-flex {
  display: flex;
}

.bob {
  display: flex;
  justify-content: center;
  width: 800px;
}

.who {
  font-size: 25px;
  margin-left: -20px;
  margin-bottom: 10px;
}


html {
  scroll-behavior: smooth;
}

@media screen and (min-width: 280px) and (max-width: 822px) {
  #menu{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .top-links {
    margin-left: 20px;
  }

  .top-links a{
    font-size: 15px;
  }
}

